import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";

//jquery
import $ from "jquery";

//boostrap
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min";

//pinia
import { createPinia } from "pinia";
const pinia = createPinia();

//i18n
import { i18n } from "./boot/i18n.js";

// FontAwesome
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { dom } from "@fortawesome/fontawesome-svg-core";
import "vue3-openlayers/styles.css";

import CookieConsentVue from './lib/CookieConsentVue.js'
import Paginate from "vuejs-paginate-next";

// esto es para los mapas
import Vue3OpenLayers from "vue3-openlayers";
import "./assets/css/main.css";

library.add(fab);
library.add(far);
library.add(fas);

dom.watch();


//create app and use
createApp(App)
.use(router).use($)
.use(CookieConsentVue, {
    // your config. goes here (required)
})
.use(pinia).use(i18n)
.use(Vue3OpenLayers)
.use(Paginate)
.component('font-awesome-icon', FontAwesomeIcon)
.mount("#app");
