import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: "/",
    name: "home",
    component: () => import("@/layouts/tema_light/index_layout.vue"),
    children: [
      {
        path: "/",
        name: "main",
        title:'en el router',
        component: () => import("@/views/tema_light/view_main.vue"),
      },
      {
        path: "/jornada/:nombre_seo_jornada",
        name: "ficha_jornada",
        component: () => import("@/views/tema_light/view_ficha_jornada.vue"),
      },
      {
        path: "/event/:nombre_seo_jornada",
        name: "event",
        idioma: "en",
        component: () => import("@/views/tema_light/view_ficha_jornada.vue"),
      },
      {
        path: "/jornada/:nombre_seo_jornada/:nombre_seo_participante",
        name: "ficha_jornada_participante",
        component: () => import ("@/views/tema_light/view_ficha_participante.vue")
      },
      {
        path: "/:catchAll(.*)",
        component: () => import ("@/views/tema_light/not_found.vue")
      },
      
    ],
  },


  {
    path: "/textos-legales",
    name: "",
    component: () => import("@/layouts/tema_light/textos_layout.vue"),
    children: [
      {
        path: "/textos-legales/:url_texto",
        name: "texto-legal",
        component: () => import("@/views/tema_light/view_texto_legal.vue"),

      }
    ]
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0 };
    }
  }
});

export default router;
