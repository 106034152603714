export default {
  mas_info: "More Info",
  restaurantes: "Restaurants",
  alojamientos: "Lodging",
  informacion: "Information",
  propuesta: "Proposal",
  volver: "Back",
  contacta_con_nosotros: "Contact us",
  reservar: "Reservations",
  inicio: "Home",
  eventos: "Events",
  precio: "Price"

};
