export default {
  mas_info: "Más Info",
  restaurantes: "Restaurantes",
  alojamientos: "Alojamientos",
  informacion: "Información",
  propuesta: "Propuesta",
  volver: "Volver",
  contacta_con_nosotros: "Contacta con nosotros",
  reservar: "Reservar",
  inicio: "Inicio",
  eventos: "Eventos",
  horarios: "Horarios",
  precio: "Precio"

};
