import { createI18n } from 'vue-i18n'
import messages from '../i18n' 

// https://vue-i18n.intlify.dev/guide/advanced/composition.html

const i18n = createI18n({
  legacy: false,
  locale: 'es',
  fallbackLocale: 'en',
  globalInjection: true,
  messages
})

export { i18n }

